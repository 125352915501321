import React, {useMemo} from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useMediaQuery } from "@material-ui/core";
import {QueryClient, QueryClientProvider} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import 'moment/locale/es';

import {darkTheme, lightTheme} from "./themes/themes";
import Layout from "./containers/Layout/Layout";
import Auth from "./containers/Admin/Settings/Auth/Auth";
import PrivateRoute from "./hoc/PrivateRoute/PrivateRoute";

moment.locale('es', {
    longDateFormat: {
        LTS : 'HH:mm:ss a',
        L : 'DD/MM/YYYY',
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 3 * 60 * 1000
        }
    }
});

const App = () => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
      () => prefersDarkMode ? darkTheme : lightTheme,
      [prefersDarkMode]);

  let routes = (
      <Switch>
          <Route path="/" exact component={Auth}/>
          <PrivateRoute path='/panel' component={Layout}/>
          <Redirect to="/"/>
      </Switch>
  );

  return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale='es'>
                <CssBaseline/>
                { routes }
            </MuiPickersUtilsProvider>
        </ThemeProvider>
          {process.env.NODE_ENV === 'development' &&
          <ReactQueryDevtools initialIsOpen={false}/>}
      </QueryClientProvider>
  );
}

export default withRouter(App);
