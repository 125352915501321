import { createMuiTheme } from "@material-ui/core";
import indigo from "@material-ui/core/colors/indigo";
import red  from "@material-ui/core/colors/red";

export const lightTheme = createMuiTheme({
    palette: {
        primary: {
            main: indigo[900]
        },
        secondary: {
            main: red[700]
        }
    }
});

export const darkTheme = createMuiTheme({
    palette: {
        primary: {
            main: indigo[900]
        },
        secondary: {
            main: red[700]
        }
    }
});