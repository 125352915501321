import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useField} from "formik";
import PropTypes from 'prop-types';

const CustomCheckbox = props => {
    const [field] = useField(props.name);
    return(
        <FormControlLabel
            control={<Checkbox {...field} color="primary" checked={field.value} disabled={props.disabled}/>}
            label={props.label}
        />
    );
}

CustomCheckbox.propTypes = {
    /**
     * Nombre del input correspondiente.
     */
    name: PropTypes.string,
    /**
     * Habilitado.
     */
    disabled: PropTypes.bool,
    /**
     * Etiqueta junto al input.
     */
    label: PropTypes.string
}

CustomCheckbox.defaultProps = {
    disabled: false
}

export default CustomCheckbox;