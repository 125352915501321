import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import {menuTypes} from "../../util/types";

function TabPanel(props) {
    const classes = useStyles();
    const { children, index } = props;

    return <Box
        className={classes.tabPanel}
        p={3}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}>
        {children}
    </Box>;
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired
};

function a11yProps(id) {
    return {
        id: id,
        'aria-controls': `scrollable-auto-tabpanel-${id}`,
        value: id
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    appBar: {
        transform: 'translateZ(0)'
    },
    iconCLose: {
        fontSize: '.9rem'
    },
    iconButton:{
        margin: theme.spacing(0, 0, 1.8, 1),
        padding: theme.spacing(0.5),
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main
        }
    },
    tabs:{
        minHeight: '35px',
        padding: theme.spacing(0)
    },
    tabText: {
        fontSize: '.75rem',
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        lineHeight: '1.5'
    },
    tabPanel: {
        backgroundColor: theme.palette.background.default,
        padding: '24px 0'
    }
}));

const MenuTabs = ({value, changeHandler, variant, activeTabs, closable, closeHandler, children}) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" color="transparent" classes={{root: classes.appBar}}>
                <Tabs
                    classes={{root: classes.tabs}}
                    value={value}
                    onChange={changeHandler}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={variant}
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example">
                    {activeTabs.map((activeTab, index) => {
                        let customTab = activeTab.title;
                        if(activeTab.id !== menuTypes.HOME && closable){
                            customTab = <Box display='flex' justifyContent='center'>
                                <Typography variant='button' classes={{root: classes.tabText}}>{activeTab.title}</Typography>
                                <IconButton classes={{root: classes.iconButton}} onClick={(event) => closeHandler(event, index, activeTab.id) }>
                                    <CloseIcon classes={{root: classes.iconCLose}}/>
                                </IconButton>
                            </Box>;
                        }
                        return (
                            <Tab component={Grid} key={activeTab.id} classes={{root: classes.tabs}}
                                 {...a11yProps(activeTab.id)}
                                 label={customTab} wrapped={true}/>
                        )
                    })}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={value}>
                {children}
            </TabPanel>
        </div>
    );
};

MenuTabs.defaultProps = {
    variant: 'standard',
    closable: false,
    headerColor: ''
};

export default MenuTabs;