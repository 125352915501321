import React from 'react';
import Skeleton from "@material-ui/lab/Skeleton";

const CustomTextSkeleton = ({height}) => {
    return (
        <>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
            <Skeleton height={height}/>
        </>
    );
}

export default CustomTextSkeleton;