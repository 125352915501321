import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import {useField} from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1)
    }
}));

const InputText = ({label, adornment, adornmentPos, ...props}) => {
    const classes = useStyles();

    const [field, meta] = useField(props);

    let inputProps = {
        readOnly: props.readOnly
    };
    if(adornment && adornmentPos){
        if(adornmentPos === "start")
            inputProps.startAdornment = <InputAdornment position={adornmentPos}>{adornment}</InputAdornment>
        else{
            inputProps.endAdornment = <InputAdornment position={adornmentPos}>{adornment}</InputAdornment>
        }
    }

    return (
        <TextField
            autoComplete={props.autoComplete}
            error={!!(meta.touched && meta.error)}
            label={label}
            fullWidth
            InputProps={inputProps}
            {...field}
            {...props}
            helperText={meta.touched && meta.error ? meta.error : null}
            classes={{root: classes.root}}
        />
    )
};

InputText.propTypes = {
    /**
     * Label del input
     */
    label: PropTypes.string.isRequired,
    /**
     * Icono en forma de componente
     */
    adornment: PropTypes.object,
    /**
     * Posición del icono
     */
    adornmentPos: PropTypes.oneOf(['start', 'end']),
    /**
     * Solo lectura
     */
    readOnly: PropTypes.bool,
    /**
     * Autocompletado
     */
    autoComplete: PropTypes.string,
    /**
     * Nombre
     */
    name: PropTypes.string,
    /**
     * Tipo
     */
    type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
    /**
     * Si se posiciona el cursor en el campo
     */
    autoFocus: PropTypes.bool
}

InputText.defaultProps = {
    readOnly: false
}

export default InputText;