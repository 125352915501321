import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputText from "../../../../components/UI/InputText/InputText";
import useMutationLogin from "../../../../hooks/auth/mutations/useMutationLogin";
import CustomCheckbox from "../../../../components/UI/CustomCheckbox/CustomCheckbox";
import { httpErrorsTypes } from "../../../../util/types";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.cubana.cu">
                Cubana de Aviación
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Auth = ({history}) => {
    const classes = useStyles();

    const [initialValues, setInitialValues] = useState({
        userName: '',
        password: '',
        rememberMe: false
    });

    const [showPassword, setShowPassword] = useState(false);


    const authMutation = useMutationLogin();

    const [error, setError] = useState(null);

    const onAuth = async ({userName, password, rememberMe}) => {
        await authMutation.mutateAsync({userName, password}, {
            onSuccess: ({data}, variables) => {
                sessionStorage.setItem('token', data.token);
                sessionStorage.setItem('refresh-token', data['refresh-token']);
                sessionStorage.setItem('userName', variables.userName);
                sessionStorage.setItem('isDeveloper', data.desarrollo);
                const permissions = data.permissions.api;
                sessionStorage.setItem('permissions', JSON.stringify(permissions));
                if(rememberMe){
                    localStorage.setItem('cubana_loginUserName', variables.userName);
                    localStorage.setItem('cubana_rememberMe', rememberMe);
                }
                else{
                    localStorage.setItem('cubana_loginUserName', '');
                    localStorage.setItem('cubana_rememberMe', '');
                }
                history.replace('/panel');
            },
            onError: (error, variables) => {
                if(error.response)
                    setError(httpErrorsTypes(error.response.status));
            }
        });
    }

    const clickShowPasswordHandler = () => {
        setShowPassword(!showPassword);
    }

    const mouseDownPasswordHandler = (event) => {
        event.preventDefault();
    }

    useEffect(() => {
        if(localStorage.cubana_rememberMe && localStorage.cubana_rememberMe === "true" &&
            localStorage.cubana_loginUserName){
            setInitialValues({
                userName: localStorage.getItem('cubana_loginUserName'),
                password: '',
                rememberMe: true
            });
        }
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar src='/logo_CU.png' variant='square' alt='Cubana de Aviación' className={classes.avatar}/>
                <Typography component="h1" variant="h5">
                    Autenticarse
                </Typography>
                {error && (
                    <Typography variant="body1" color='error'>
                        {error}
                    </Typography>
                )}
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={Yup.object({
                        userName: Yup.string().email('Formato de correo no válido.').required('Requerido'),
                        password: Yup.string().min(6, 'Valor mínimo 6 caracteres.').required('Requerido')
                    })}
                    onSubmit={async (values) => {
                        await onAuth(values);
                    }}>
                    {({isSubmitting}) => (
                        <Form>
                            <InputText
                                label="Correo eléctronico"
                                name="userName"
                                type='email'
                                autoFocus/>
                            <InputText
                                name="password"
                                label="Contraseña"
                                type={showPassword ? 'text' : 'password'}
                                adornment={<IconButton
                                    aria-label='toggle password visibility'
                                    onClick={clickShowPasswordHandler}
                                    onMouseDown={mouseDownPasswordHandler}>
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>}
                                adornmentPos='end'/>
                            <CustomCheckbox
                                name='rememberMe'
                                label='Recordar'/>
                            <Button
                                disabled={isSubmitting}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Entrar
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default Auth;

