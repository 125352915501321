import axios from 'axios';

import {serverUrl} from "./urls";

export default axios.create({
    baseURL: serverUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});
