import {useQuery} from "react-query";

import axiosInstance from "../../../http/axios";
import {restUrls} from "../../../http/urls";
import {dataTypes} from "../../../util/types";
import {setAuthHeaders} from "../../../util/util";

const getTree = async (key) => {
    setAuthHeaders();
    const {data} = await axiosInstance.get(restUrls[dataTypes.MENU]);
    return data;
}

const useQueryTree = () => {

    return useQuery(['treeMenu'], getTree, {
        staleTime: 30 * 60 * 1000
    });
}

export default useQueryTree;