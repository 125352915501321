import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import Folder from '@material-ui/icons/Folder';
import GridOn from '@material-ui/icons/GridOn';
import Security from '@material-ui/icons/Security';
import FlightIcon from '@material-ui/icons/Flight';

import {iconNameTypes} from '../../util/types';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText
    },
    treeItemRoot: {
        padding: theme.spacing(1),
    },
    treeItemLabel:{
        fontSize: '.9rem'
    },
    errorMessage:{
        color: theme.palette.error.main
    },
    container: {
        height: 'fit-content'
    },
    selectedItem: {
        color: theme.palette.secondary.light
    }
}));

const getIcon = (iconName) => {
    switch (iconName) {
        case(iconNameTypes.HOME):
            return <Home/>;
        case(iconNameTypes.SETTINGS):
            return <Settings/>;
        case(iconNameTypes.SECURITY):
            return <Security/>;
        case (iconNameTypes.APPLICATION):
            return <FlightIcon/>;
        default:
            return <GridOn/>;
    }
};

const MenuTree = props => {

    const classes = useStyles();

    const treeRender = (data) => {
        let subTree = null;
        if (data.children) {
            subTree = data.children.map(treeNode => {
                return treeRender(treeNode);
            });
        }
        return (
            <TreeItem
                classes={{ root: classes.treeItemRoot, label: classes.treeItemLabel, selected: classes.selectedItem}}
                key={data.id}
                icon={data.icon ? getIcon(data.icon) : null}
                nodeId={data.id}
                label={data.text}
                onLabelClick={data.leaf ? (event) => props.handleSelect(event, data.id, data.text) : null}>
                { subTree }
            </TreeItem>
        );
    };

    const treeItems = props.treeItems.map((treeNode) => {
        return treeRender(treeNode);
    });

    return (
        <TreeView
            className={classes.root}
            expanded={props.expanded}
            selected={props.selectedNode}
            defaultParentIcon={<Folder/>}
            defaultEndIcon={<GridOn/>}
            onNodeToggle={props.handleToggle}>
            { treeItems }
        </TreeView>
    );
};

export default MenuTree;