import React from 'react';
import HomeTitle from "../../../../components/HomeTitle/HomeTitle";

const Home = () => {
    return (
        <HomeTitle/>
    );
}

export default Home;
