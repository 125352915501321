import axiosInstance from '../http/axios';
import {httpErrorsTypes, stateAmadeusType, statesType} from "./types";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};

export const updateServerState = (oldState, editedItem) => {
    let stateData = [...oldState.data];
    const index = stateData.findIndex(item => item.id === editedItem.id);
    stateData[index] = editedItem;
    return {...oldState, data: stateData};
}

export const replaceServerState = (oldState, newItem) => {
    let stateData = [...oldState.data];
    if (stateData.length >= 20) {
        stateData.splice(-1, 1);
    }
    stateData.unshift(newItem);
    let stateTotal = oldState.totalCount + 1;
    return {data: stateData, totalCount: stateTotal};
}

export const deleteServerState = (oldState, {ids}) => {
    let stateData = [...oldState.data];
    for (let id of ids){
        const index = stateData.findIndex(item => item.id === id);
        stateData.splice(index, 1);
    }
    let stateTotal = oldState.totalCount - ids.length;
    return {data: stateData, totalCount: stateTotal};
}

export const deleteOneServerState = (oldState, {id}) => {
    let stateData = [...oldState.data];
    const index = stateData.findIndex(item => item.id === id);
    stateData.splice(index, 1);
    let stateTotal = oldState.totalCount - 1;
    return {data: stateData, totalCount: stateTotal};
}

export const logout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh-token');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('permissions');
    sessionStorage.removeItem('isDeveloper');
};

export const additionalData = (tree) => {
    return tree.map((treeNode) => {
        if(!treeNode.leaf && treeNode.children){
            const updatedChildren = additionalData(treeNode.children);
            return {
                ...treeNode,
                open: false,
                children: updatedChildren
            }
        }
        else{
            return treeNode;
        }
    });
}

export const getAccessToken = () => {
    return sessionStorage.getItem('token');
}

export const getRefreshToken = () => {
    return sessionStorage.getItem('refresh-token');
}

export const getPermissions = () => {
    return JSON.parse(sessionStorage.getItem('permissions'));
}

export const isDeveloper = () => {
    return sessionStorage.getItem('isDeveloper') === 'true';
}

export const getSpecificPermissions = (resource) => {
    const generalPermissions = getPermissions();
    return generalPermissions[resource];
}

export const getUserName = () => {
    return sessionStorage.getItem('userName');
}

export const setAuthHeaders = () => {
    const token = getAccessToken();
    if (typeof token === 'string') {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
}

export const convertJsonToArray = (jsonObject) => {
    return Object.keys(jsonObject).map(key => {
        return {
            [key]: jsonObject[key]
        }
    });
}

export const createPercent = (percent) => {
    switch (Math.sign(percent)) {
        case 1:
            return `+${percent}%`;
        case -1:
            return `${percent}%`;
        default:
            return `${percent}%`;
    }
};

export const serverErrorMessage = (serverError) => {
    if(serverError.response){
        if(serverError.response.messages){
            return serverError.response.messages.error;
        }
        else if(serverError.response.data && serverError.response.data.messages){
            return serverError.response.data.messages.error;
        }
    else
        return httpErrorsTypes(serverError.response.status);
    }
    return '';
}

export const convertToLookup = (dataArray) => {
   return dataArray.reduce(function(acc, cur, i) {
       acc[cur.id] = cur.name;
       return acc;
   }, {});
}

export function isInt(n){
    if(isNaN(n))
        return false;
    return Number(n) === n && n % 1 === 0;
}

export function isFloat(n){
    if(isNaN(n))
        return false;
    return Number(n) === n && n % 1 !== 0;
}

export function convertDateToString(date){
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    return year + "_" + month + "_" + day;
}

export const generalReducer = (state, action) => {
    switch (action.type){
        case 'SET_DATA_TABLE':
            let stateFilter = state.filter;
            let updateFilter = null;
            if (action.filter) {
                if (isJSON(action.filter)) {
                    let actionFilter = JSON.parse(action.filter);
                    updateFilter = updateObject({}, actionFilter);

                    if(state.startDate) {
                        updateFilter = updateObject(updateFilter, {'startDate': state.startDate});
                    }
                    if(state.endDate) {
                        updateFilter = updateObject(updateFilter, {'endDate': state.endDate});
                    }
                } else updateFilter = updateObject(stateFilter, action.filter);
            } else {
                if (state.startDate || state.endDate) {
                    if(state.startDate) {
                        updateFilter = updateObject({}, {'startDate': state.startDate});
                    }
                    if(state.endDate) {
                        updateFilter = updateObject(updateFilter, {'endDate': state.endDate});
                    }
                }
            }
            return updateObject(state, {
                q: action.q,
                sort: action.sort,
                page: action.page,
                order: action.order,
                filter: updateFilter
            });
        case 'TOGGLE_DELETE':
            return updateObject(state,{
                openDeleteModal: action.openDeleteModal,
                selectedToDelete: action.selectedToDelete
            });
        case 'TOGGLE_LANG_MODAL':
            return updateObject(state,{
                openLangModal: action.openLangModal,
                selectedLang: action.selectedLang
            });
        case 'CHANGE_LANG':
            return updateObject(state, {
                selectedLang: action.selectedLang
            });
        case 'SET_RANGE_DATE': {
            let updatedFilters = state.filter;
            if(state.filter && Object.keys(state.filter).length > 0){
                let dateField = 'startDate';
                if(action.dateObject.hasOwnProperty('endDate')) {
                    dateField = 'endDate';
                }
                if(action.dateObject[dateField]) {
                    Object.assign(updatedFilters, action.dateObject);
                }
                else {
                    delete updatedFilters[dateField];
                }
            }
            else if(action.dateObject.startDate || action.dateObject.endDate) {
                updatedFilters = action.dateObject;
            }
            return updateObject(state, {
                ...action.dateObject,
                filter: updatedFilters
            });
        }
        default:
            throw new Error('No se puede llegar aqui');
    }
};

export const init = (initialState) => {
    return {...initialState};
}

export const drawerReducer = (state, action) => {
    switch (action.type) {
        case 'SET_MUTATE_ERROR':
            return updateObject(state, {
                mutateError: action.error
            });
        case 'OPEN_EDIT_DRAWER':
            return updateObject(
                state,{
                    openDrawer: true,
                    selected:  action.selected
                }
            );
        case 'CLOSE_DRAWER':
            return init(action.initialState);
        case 'OPEN_ADD_DRAWER':
            return updateObject(state, {
                openDrawer: true
            });
        case 'SET_FORM_DATA':
            return updateObject(state, {
                form: action.formData,
                editor: action.editorState,
                files: action.files
            });
        case 'SET_FILES':
            return updateObject(state,{
                files: action.files
            });
        case 'TOGGLE_ACTIVE_LABEL':
            return updateObject(state, {
                isLabelFocused: action.isLabelActive
            });
        case 'SET_EDITOR':
            return updateObject(state, {
                editor: action.editorState
            });
        default:
            throw new Error('No se puede llegar aqui');
    }
};

export const setDataTableHandler = (q, sort, page, order, filter, handler) => {
    handler({
        type: 'SET_DATA_TABLE',
        q: q,
        sort: sort,
        page: page,
        order: order,
        filter: filter
    });
};

export const setDateRangeHandler = (isInitial, date, handler) => {
    if(date !== 'Fecha inválida'){
        const dateObject = {};
        if(isInitial)
            dateObject.startDate = date;
        else
            dateObject.endDate = date;
        handler({
            type: 'SET_RANGE_DATE',
            dateObject: dateObject
        });
    }
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

//validaciones
export const required = (field) => {
    return (typeof field === 'undefined' || field === '');
}

export const number = (field) => {
    return isNaN(field);
}

export const integer = (field) => {
    return !Number.isInteger(field);
}

export const maxLength = (field, maxLength) => {
    return field.length > maxLength;
}

export const exactLength = (field, length) => {
    return field.length !== length;
}

export function email(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(email);
}

export function url(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !pattern.test(str);
}

export function stateSelect(stateId) {
    let state;
    switch (stateId.toString()) {
        case statesType.STATENON:
           state = stateAmadeusType[statesType.STATENON];
           break;
        case statesType.STATE1:
            state = stateAmadeusType[statesType.STATE1];
            break;
        case statesType.STATE2:
            state = stateAmadeusType[statesType.STATE2];
            break;
        case statesType.STATE3:
            state = stateAmadeusType[statesType.STATE3];
            break;
        case statesType.STATE4:
            state = stateAmadeusType[statesType.STATE4];
            break;
        case statesType.STATE5:
            state = stateAmadeusType[statesType.STATE5];
            break;
        case statesType.STATE6:
            state = stateAmadeusType[statesType.STATE6];
            break;
        case statesType.STATE7:
            state = stateAmadeusType[statesType.STATE7];
            break;
        case statesType.STATE8:
            state = stateAmadeusType[statesType.STATE8];
            break;
        default:
            state = stateAmadeusType[statesType.STATE1];
            break;
    }
    return state;
}

export function isJSON(value: any) {
    try {
        const result = JSON.parse(value);
        return !!result;
    } catch {
        return false;
    }
}

