import React, {useState, Suspense, useEffect, useReducer} from 'react';
import {Redirect, Switch} from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import {useQueryClient} from "react-query";

import MenuTree from "../../components/MenuTree/MenuTree";
import MenuAppBar from "../../components/MenuAppBar/MenuAppBar";
import useQueryTree from "../../hooks/treeMenu/queries/useQueryTree";
import {convertJsonToArray, getPermissions, getUserName, logout, updateObject} from "../../util/util";
import withRefreshHandler from "../../hoc/withRefreshHandler/withRefreshHandler";
import axiosInstance from '../../http/axios';
import PrivateRoute from "../../hoc/PrivateRoute/PrivateRoute";
import Home from "../Admin/Settings/Home/Home";
import {httpErrorsTypes, menuTypes, navTypes, navUrls} from "../../util/types";
import AuthContext from '../../context/auth-context';
import MenuTabs from "../../components/MenuTabs/MenuTabs";
import CustomTextSkeleton from "../../components/UI/CustomTextSkeleton/CustomTextSkeleton";

const Airlines = React.lazy(() => {
   return import ('../Admin/Settings/Airlines/Airlines');
});

const Airports = React.lazy(() => {
    return import ('../Admin/Settings/Airports/Airports');
});

const Articles = React.lazy(() => {
    return import ('../Admin/PageSettings/Articles/Articles');
});

const AmadeusLogs = React.lazy(() => {
    return import ('../Security/Settings/AmadeusLogs/AmadeusLogs');
});

const BlackList = React.lazy( () => {
    return import ('../Security/Settings/BlackList/BlackList');
});

const Cards = React.lazy(() => {
    return import('../Admin/Settings/Cards/Cards');
});

const Clients = React.lazy(() => {
   return import ('../Admin/Clients/Clients');
});

const ClientTypes = React.lazy(() => {
   return import('../Admin/Clients/ClientTypes');
});

const Countries = React.lazy(() => {
    return import('../Admin/Settings/Countries/Countries');
});

const Dashboard = React.lazy(() => {
    return import('../Admin/Settings/Dashboard/Dashboard');
});

const Destinies = React.lazy(() => {
   return import('../Admin/PageSettings/Destinies/Destinies');
});

const EmailLogs = React.lazy(() => {
   return import('../Security/Settings/EmailLogs/EmailLogs');
});

const EmailTemplates = React.lazy(() => {
    return import ('../Security/Settings/EmailTemplates/EmailTemplates');
});

const FooterCategories = React.lazy(() => {
   return import('../Admin/PageSettings/FooterCategories/FooterCategories');
});

const FooterMenu = React.lazy(() => {
   return import('../Admin/PageSettings/FooterMenu/FooterMenu');
});

const Flights = React.lazy(() => {
   return import('../Applications/Flights/Flights');
});

const FlightClasses = React.lazy(() => {
   return import('../Admin/Settings/FlightClasses/FlightClasses');
});

const FlightSchemas = React.lazy(() => {
    return import('../Applications/FlightSchemas/FlightSchemas');
});

const HistReservations = React.lazy(() => {
    return import('../Reports/HistoyReservations/HistoryReservations');
});

const Municipes = React.lazy(() => {
    return import('../Admin/Settings/Municipes/Municipes') ;
});

const Payways = React.lazy(() => {
   return import ('../Admin/Settings/PayWay/PayWay');
});

const Places = React.lazy(() => {
   return import('../Admin/Settings/Places/Places');
});

const Planes = React.lazy(() => {
    return import('../Admin/Settings/Planes/Planes');
});

const PrincipalMenu = React.lazy(() => {
   return import('../Admin/PageSettings/PrincipalMenu/PrincipalMenu');
});

const MenuCategories = React.lazy(() => {
   return import('../Admin/PageSettings/MenuCategories/MenuCategories');
});

const HelpCategories = React.lazy(() => {
    return import('../Admin/PageSettings/HelpCategories/HelpCategories');
});

const Problems = React.lazy(() => {
   return import('../Admin/Settings/Problems/Problems');
});

const Provinces = React.lazy(() => {
    return import('../Admin/Settings/Provinces/Provinces');
});

const Publicities = React.lazy(() => {
   return import('../Admin/PageSettings/Publicities/Publicities');
});

const System = React.lazy(() => {
    return import('../Security/Settings/System/System');
});

const Currencies = React.lazy(() => {
    return import('../Admin/Settings/Currencies/Currencies');
});

const Languages = React.lazy(() => {
    return import ('../Admin/Settings/Languages/Languages');
});

const Logs = React.lazy(() => {
    return import ('../Security/Settings/Logs/Logs');
});

const Regions = React.lazy(() => {
    return import ('../Admin/Settings/Regions/Regions');
});

const Reservations = React.lazy(() => {
    return import ('../Reports/Reservations/Reservations');
});

const Roles = React.lazy(() => {
    return import('../Security/Settings/Roles/Roles');
});

const SocialMedias = React.lazy(() => {
   return import('../Admin/PageSettings/SocialMedias/SocialMedias');
});

const SlideShows = React.lazy(() => {
   return import('../Admin/PageSettings/SlideShow/SlideShow');
});

const Users = React.lazy(() => {
    return import('../Security/Settings/Users/Users');
});

const Translates = React.lazy(() => {
    return import('../Admin/Translates/Translates');
});

const PaymentsOut = React.lazy(() => {
    return import('../Reports/PaymentsOut/PaymentsOut');
})


const drawerWidth = 220;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    toolbar: theme.mixins.toolbar,
    titleBox:{
        backgroundColor: theme.palette.background.paper
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.primary.dark
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1280,
            padding: theme.spacing(3, 0, 3, 3),
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 750,
            maxWidth: 1100,
            padding: theme.spacing(3),
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '360px',
            padding: theme.spacing(3),
        }
    },
    pageTitleText: {
        color: theme.palette.secondary.light,
        fontSize: '1.2rem'
    },
    icon:{
        color: theme.palette.secondary.light,
        fontSize: '1.6rem',
        paddingTop: '3px',
        marginRight: '5px'
    },
    errorMenuText: {
        color: theme.palette.primary.contrastText
    },
    menuImage: {
        width: '100%',
        height: theme.spacing(7.8),
        '& > img':{
            objectFit: 'contain'
        }
    }
}));

const getRoutes = () => {
    let routes = [];
    const permissions = getPermissions();
    if(permissions.hasOwnProperty('dashboard') && permissions.dashboard.index)
        routes.push(<PrivateRoute key='home' path='/panel' exact component={Home}/>);
    else
        routes.push(<PrivateRoute key='home' path='/panel' exact component={Home}/>);
    for (const permission of convertJsonToArray(permissions)){
        if(!Object.keys(permission).includes('dashboard') &&
            !Object.keys(permission).includes('auth') &&
            !Object.keys(permission).includes('menu')){
            const permissionKey = Object.keys(permission)[0];
            let component;
            let route;
            switch (permissionKey) {
                case menuTypes.AIRLINES:
                    component = Airlines;
                    route = navUrls[menuTypes.AIRLINES];
                    break;
                case menuTypes.AIRPORTS:
                    component = Airports;
                    route = navUrls[menuTypes.AIRPORTS];
                    break;
                case menuTypes.ARTICLES:
                    component = Articles;
                    route = navUrls[menuTypes.ARTICLES];
                    break;
                case  menuTypes.AMADEUSLOG:
                    component = AmadeusLogs;
                    route = navUrls[menuTypes.AMADEUSLOG];
                    break;
                case menuTypes.BLACKLIST:
                    component = BlackList;
                    route = navUrls[menuTypes.BLACKLIST];
                    break;
                case menuTypes.CARD:
                    component = Cards;
                    route = navUrls[menuTypes.CARD];
                    break;
                case menuTypes.COUNTRY:
                    component = Countries;
                    route = navUrls[menuTypes.COUNTRY];
                    break;
                case menuTypes.CLIENTS:
                    component = Clients;
                    route = navUrls[menuTypes.CLIENTS];
                    break;
                case menuTypes.CLIENTTYPES:
                    component = ClientTypes;
                    route = navUrls[menuTypes.CLIENTTYPES];
                    break;
                case menuTypes.CURRENCY:
                    component = Currencies;
                    route = navUrls[menuTypes.CURRENCY];
                    break;
                case menuTypes.DESTINIES:
                    component = Destinies;
                    route = navUrls[menuTypes.DESTINIES];
                    break;
                case menuTypes.EMAILLOG:
                    component = EmailLogs;
                    route = navUrls[menuTypes.EMAILLOG];
                    break;
                case menuTypes.EMAILTEMPLATE:
                    component = EmailTemplates;
                    route = navUrls[menuTypes.EMAILTEMPLATE];
                    break;
                case menuTypes.FOOTERCATEGORIES:
                    component = FooterCategories;
                    route = navUrls[menuTypes.FOOTERCATEGORIES];
                    break;
                case menuTypes.FOOTERMENU:
                    component = FooterMenu;
                    route = navUrls[menuTypes.FOOTERMENU];
                    break;
                case menuTypes.FLIGHTS:
                    component = Flights;
                    route = navUrls[menuTypes.FLIGHTS];
                    break;
                case menuTypes.FLIGHTCLASSES:
                    component = FlightClasses;
                    route = navUrls[menuTypes.FLIGHTCLASSES];
                    break;
                case menuTypes.FLIGHTSCHEMAS:
                    component = FlightSchemas;
                    route = navUrls[menuTypes.FLIGHTSCHEMAS];
                    break;
                case menuTypes.HISTORYRESERVATIONS:
                    component = HistReservations;
                    route = navUrls[menuTypes.HISTORYRESERVATIONS];
                    break;
                case menuTypes.HELPCATEGORIES:
                    component = HelpCategories;
                    route = navUrls[menuTypes.HELPCATEGORIES];
                    break;
                case menuTypes.LANG:
                    component = Languages;
                    route = navUrls[menuTypes.LANG];
                    break;
                case menuTypes.LOG:
                    component = Logs;
                    route = navUrls[menuTypes.LOG];
                    break;
                case menuTypes.PRINCMENUCATEGORIES:
                    component = MenuCategories;
                    route = navUrls[menuTypes.PRINCMENUCATEGORIES];
                    break;
                case menuTypes.MUNICIPE:
                    component = Municipes;
                    route = navUrls[menuTypes.MUNICIPE];
                    break;
                case menuTypes.REGIONS:
                    component = Regions;
                    route = navUrls[menuTypes.REGIONS];
                    break;
                case menuTypes.RESERVATIONS:
                    component = Reservations;
                    route = navUrls[menuTypes.RESERVATIONS];
                    break;
                case menuTypes.ROLE:
                    component = Roles;
                    route = navUrls[menuTypes.ROLE];
                    break;
                case menuTypes.USER:
                    component = Users;
                    route = navUrls[menuTypes.USER];
                    break;
                case menuTypes.PAYWAY:
                    component = Payways;
                    route = navUrls[menuTypes.PAYWAY];
                    break;
                case menuTypes.PLACES:
                    component = Places;
                    route = navUrls[menuTypes.PLACES];
                    break;
                case menuTypes.PLANES:
                    component = Planes;
                    route = navUrls[menuTypes.PLANES];
                    break;
                case menuTypes.PROBLEMS:
                    component = Problems;
                    route = navUrls[menuTypes.PROBLEMS];
                    break;
                case menuTypes.PRINCIPALMENU:
                    component = PrincipalMenu;
                    route = navUrls[menuTypes.PRINCIPALMENU];
                    break;
                case menuTypes.PROVINCE:
                    component = Provinces;
                    route = navUrls[menuTypes.PROVINCE];
                    break;
                case menuTypes.PUBLICITIES:
                    component = Publicities;
                    route = navUrls[menuTypes.PUBLICITIES];
                    break;
                case menuTypes.SOCIALMEDIAS:
                    component = SocialMedias;
                    route = navUrls[menuTypes.SOCIALMEDIAS];
                    break;
                case menuTypes.SLIDESHOW:
                    component = SlideShows;
                    route = navUrls[menuTypes.SLIDESHOW];
                    break;
                case menuTypes.SYSTEM:
                    component = System;
                    route = navUrls[menuTypes.SYSTEM];
                    break;
                case menuTypes.TRANSLATES:
                    component = Translates;
                    route = navUrls[menuTypes.TRANSLATES];
                    break;
                case menuTypes.PAYMENTOUT:
                    component = PaymentsOut;
                    route = navUrls[menuTypes.PAYMENTOUT];
                    break;
                default:
                    component = Home;
                    route = navUrls[menuTypes.HOME];
                    break;
            }
            routes.push((<PrivateRoute key={permissionKey} path={route} exact component={component}/>));
        }
    }
    return (
        <Switch>
            {routes}
            <Redirect to='/panel' />
        </Switch>
    );
}

const intialState = {
    expandedPages: [],
    activeTabs: [{id: menuTypes.HOME, title: navTypes.HOME}],
    selectedPage: menuTypes.HOME
}

const menuReducer = (state, action) => {
    switch (action.type) {
        case 'SET_MENU_VALUES':
            return updateObject(state, {
                selectedPage: action.selectedPage,
                expandedPages: action.expandedPages,
                activeTabs: action.activeTabs
            });
        case 'SET_EXPANDED_PAGES':
            return updateObject(state, {
               expandedPages: action.expandedPages
            });
        case 'RESET_PAGE':
            return updateObject(state, {
                selectedPage: ''
            });
        case 'SET_ACTIVE_PAGE': {
            if(state.selectedPage === action.selectedPage)
                return state;
            const index = state.activeTabs.findIndex(result => result.id === action.selectedPage);
            if(index === -1){
                const updatedActivePages = state.activeTabs.concat({
                    id: action.selectedPage,
                    title: action.label
                });
                return updateObject(state, {
                    activeTabs: updatedActivePages,
                    selectedPage: action.selectedPage
                });
            }
            return updateObject(state, {
                selectedPage: action.selectedPage
            });
        }
        case 'CLOSE_ACTIVE_PAGE':{
            return updateObject(state, {
                activeTabs: state.activeTabs.filter( (item) => item.id !== action.toDelete),
                selectedPage: action.selectedPage
            });
        }
        default:
            throw new Error('No se puede llegar aqui');
    }
};

const Layout = ({history, container}) => {

    const classes = useStyles();
    const theme = useTheme();
    const queryClient = useQueryClient();

    const [isMobile, setMobileOpen] = useState(false);

    const userName = getUserName();

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching
    } = useQueryTree();

    const[menuActions, setMenuActions] = useReducer(menuReducer, intialState);

    const drawerToggleHandler = () => {
        setMobileOpen(!isMobile);
    }

    const handleSelect = (event, nodeId, label) => {
        if(isMobile)
            drawerToggleHandler();
        setMenuActions({
            type: 'SET_ACTIVE_PAGE',
            selectedPage: nodeId,
            label: label
        });
        sessionStorage.setItem('selectedPage', nodeId);
    }

    const handleToggle = (event, nodeIds) => {
        setMenuActions({
            type: 'SET_EXPANDED_PAGES',
            expandedPages: nodeIds
        });
        sessionStorage.setItem('expandedPages', JSON.stringify(nodeIds));
    }

    const handleSelectTab = (event, newValue) => {
        if(!event.defaultPrevented)
            setMenuActions({
                type: 'SET_ACTIVE_PAGE',
                selectedPage: newValue
            });
    }

    const handleCloseTab = (event, activeTabIndex, nodeId) => {
        event.preventDefault();
        let found = menuActions.activeTabs.find((item, index) => index === activeTabIndex - 1);
        const selectedPage = found ? found.id : navTypes.HOME;
        setMenuActions({
            type: 'CLOSE_ACTIVE_PAGE',
            selectedPage: selectedPage,
            toDelete: nodeId
        });
    }

    //region MenuAppBar

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    async function handleClose(reason = '') {
        if(reason === 'L'){
            logout();
            history.replace('/');
            await queryClient.removeQueries();
        }
        setAnchorEl(null);
    }

    //endregion

    useEffect(() => {
        if(sessionStorage.selectedPage && sessionStorage.expandedPages){
            setMenuActions({
                type: 'SET_MENU_VALUES',
                selectedPage: sessionStorage.getItem('selectedPage'),
                expandedPages: JSON.parse(sessionStorage.getItem('expandedPages')),
                activeTabs: JSON.parse(sessionStorage.getItem('activeTabs'))
            });
        }
        return () => {
            sessionStorage.removeItem('selectedPage');
            sessionStorage.removeItem('expandedPages');
            sessionStorage.removeItem('activeTabs');
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('selectedPage', menuActions.selectedPage);
        sessionStorage.setItem('activeTabs', JSON.stringify(menuActions.activeTabs));
        history.push(navUrls[menuActions.selectedPage]);
    }, [menuActions.selectedPage, history]);

    let errorText;
    if(isError)
        errorText = error.response ? httpErrorsTypes(error.response.status) : '';

    let treeItems =
        isLoading || isFetching
            ? <CustomTextSkeleton/>
            : isError
            ? <Typography align='center' classes={{root: classes.errorMenuText}}>{errorText}</Typography>
            : <MenuTree treeItems={data}
                    expanded={menuActions.expandedPages}
                    handleSelect={handleSelect}
                    selectedNode={menuActions.selectedPage}
                    handleToggle={handleToggle}/>;

    const drawer = (
        <div className={classes.titleBox}>
            <div className={classes.toolbar}>
                <Avatar src='/cubana_gates.png' alt='Cubana' variant='square' className={classes.menuImage}>
                    <Typography className={classes.pageTitleText} variant="subtitle2">Cubana</Typography>
                </Avatar>
            </div>
            <Divider />
            { treeItems }
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <MenuAppBar
                anchorEl={anchorEl}
                handleMenu={handleMenu}
                handleClose={handleClose}
                appBarClass={classes.appBar}
                menuButtonClass={classes.menuButton}
                onClick={drawerToggleHandler}
                authenticated={true}
                userName={userName}/>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={isMobile}
                        onClose={drawerToggleHandler}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open>
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <AuthContext.Provider value={{
                    userName: userName, userSex: 'm'}}>
                    <MenuTabs
                        activeTabs={menuActions.activeTabs}
                        closable
                        changeHandler={handleSelectTab}
                        closeHandler={handleCloseTab}
                        value={menuActions.selectedPage}
                        variant='scrollable'>
                        <Suspense fallback={<CustomTextSkeleton height={50}/>}>
                            {getRoutes()}
                        </Suspense>
                    </MenuTabs>
                </AuthContext.Provider>
            </main>
        </div>
    );
}

export default withRefreshHandler(Layout, axiosInstance);
