import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from '@material-ui/core/Box';
import {useLocation} from "react-router-dom";
import {menuTypes, navTypes} from "../../util/types";
import GridOn from "@material-ui/icons/GridOn";
import {Home} from "@material-ui/icons";


const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        fontSize: '1.2rem',
        marginLeft: theme.spacing(1)
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    icon:{
        color: theme.palette.secondary.light

    },
    userText:{
        padding: '14px 0',
        color: theme.palette.secondary.light
    },
    grid:{
        flexGrow: 1
    },
    primaryText:{
        color: theme.palette.primary.main
    },
    userNameText: {
        marginRight: theme.spacing(1)
    },
}));

const MenuAppBar = ({userName, anchorEl, handleMenu, handleClose, onClick, appBarClass}) => {
    const theme = useTheme();
    const {pathname} = useLocation();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const open = Boolean(anchorEl);

    const rightButton = (
        <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit">
                {isMdUp && (
                    <Typography variant="body1" display='inline' className={classes.userNameText}>
                        {userName}
                    </Typography>
                )}
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}>
                {isSmDown && (
                    <div>
                        <MenuItem>{userName}</MenuItem>
                        <Divider/>
                    </div>
                )}
                <MenuItem onClick={() => handleClose('L')}>Salir</MenuItem>
            </Menu>
        </div>
    );

    let title;
    let titleIcon = <GridOn/>;

    if(pathname.includes(menuTypes.AIRLINES)){
        title = navTypes.AIRLINES;
    }
    else if(pathname.includes(menuTypes.AIRPORTS)){
        title = navTypes.AIRPORTS;
    }
    else if(pathname.includes(menuTypes.AIRPORTTYPES)){
        title = navTypes.AIRPORTTYPES;
    }
    else if(pathname.includes(menuTypes.AMADEUSLOG)){
        title = navTypes.AMADEUSLOG;
    }
    else if(pathname.includes(menuTypes.ARTICLES)){
        title = navTypes.ARTICLES;
    }
    else if(pathname.includes(menuTypes.BLACKLIST)){
        title = navTypes.BLACKLIST;
    }
    else if(pathname.includes(menuTypes.COUNTRY)){
        title = navTypes.COUNTRY;
    }
    else if(pathname.includes(menuTypes.CARD)){
        title = navTypes.CARD;
    }
    else if(pathname.includes(`/${menuTypes.CLIENTS}`)){
        title = navTypes.CLIENTS;
    }
    else if(pathname.includes(menuTypes.CLIENTTYPES)){
        title = navTypes.CLIENTTYPES;
    }
    else if(pathname.includes(menuTypes.DESTINIES)){
        title = navTypes.DESTINIES;
    }
    else if(pathname.includes(menuTypes.EMAILLOG)){
        title = navTypes.EMAILLOG;
    }
    else if(pathname.includes(menuTypes.FOOTERCATEGORIES)){
        title = navTypes.FOOTERCATEGORIES;
    }
    else if(pathname.includes(menuTypes.FOOTERMENU)){
        title = navTypes.FOOTERMENU;
    }
    else if(pathname.includes(menuTypes.FLIGHTS)){
        title = navTypes.FLIGHTS;
    }
    else if(pathname.includes(menuTypes.FLIGHTCLASSES)){
        title = navTypes.FLIGHTCLASSES;
    }
    else if(pathname.includes(menuTypes.FLIGHTSCHEMAS)){
        title = navTypes.FLIGHTSCHEMAS;
    }
    else if(pathname.includes(menuTypes.MUNICIPE)){
        title = navTypes.MUNICIPE;
    }
    else if(pathname.includes(menuTypes.PAYWAY)){
        title = navTypes.PAYWAYS;
    }
    else if(pathname.includes(menuTypes.PLACES)){
        title = navTypes.PLACES;
    }
    else if(pathname.includes(menuTypes.PLANES)){
        title = navTypes.PLANES;
    }
    else if(pathname.includes(menuTypes.PRINCIPALMENU)){
        title = navTypes.PRINCIPALMENU;
    }
    else if(pathname.includes(menuTypes.PRINCMENUCATEGORIES)){
        title = navTypes.PRINCMENUCATEGORIES;
    }
    else if(pathname.includes(menuTypes.PROBLEMS)){
        title = navTypes.PROBLEM;
    }
    else if(pathname.includes(menuTypes.PROVINCE)){
        title = navTypes.PROVINCE;
    }
    else if(pathname.includes(menuTypes.PUBLICITIES)){
        title = navTypes.PUBLICITIES;
    }
    else if(pathname.includes(menuTypes.REGIONS)){
        title = navTypes.REGION;
    }
    else if(pathname.includes(menuTypes.ROLE)){
        title = navTypes.ROLE;
    }
    else if(pathname.includes(menuTypes.SOCIALMEDIAS)){
        title = navTypes.SOCIALMEDIAS;
    }
    else if(pathname.includes(menuTypes.SLIDESHOW)){
        title = navTypes.SLIDESHOWS;
    }
    else if(pathname.includes(menuTypes.SYSTEM)){
        title = navTypes.SYSTEM;
    }
    else if(pathname.includes(menuTypes.USER)){
        title = navTypes.USER;
    }
    else if(pathname.includes(menuTypes.CURRENCY)){
        title = navTypes.CURRENCY;
    }
    else if(pathname.includes(menuTypes.LANG)){
        title = navTypes.LANG;
    }
    else if(pathname.includes(menuTypes.LOG)){
        title = navTypes.LOG;
    }
    else if(pathname.includes(menuTypes.EMAILLOG)){
        title = navTypes.EMAILLOG;
    }
    else if(pathname.includes(menuTypes.TRANSLATES)){
        title = navTypes.TRANSLATES;
    }
    else if(pathname.includes(menuTypes.EMAILTEMPLATE)) {
        title = navTypes.EMAILTEMPLATE;
    }
    else{
        title = navTypes.HOME;
        titleIcon = <Home/>;
    }

    return (
        <AppBar
            position="fixed"
            className={appBarClass}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={onClick}
                    className={classes.menuButton}>
                    <MenuIcon />
                </IconButton>
                {titleIcon}
                <Typography variant="h6" noWrap classes={{root: classes.title}}>
                    {title}
                </Typography>
                <Box className={classes.grid}/>
                {rightButton}
            </Toolbar>
        </AppBar>
    );
};

export default MenuAppBar;
