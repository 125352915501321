import React from "react";
import {useHistory} from 'react-router-dom';
import createAuthRefreshInterceptor from "axios-auth-refresh";

import {getRefreshToken, logout} from "../../util/util";
import axiosInstance from "../../http/axios";
import {restUrls} from "../../http/urls";
import {dataTypes} from "../../util/types";

const withRefreshHandler = (WrappedComponent, axios) => {
    return props => {
        const history = useHistory();
        const refreshAuthLogic = failedRequest => {
            const refreshToken = getRefreshToken();
            axiosInstance.post(restUrls[dataTypes.REFRESH], null, {
                headers: {'Authorization': `Bearer ${refreshToken}`},
                skipAuthRefresh: true
            })
                .then(tokenRefreshResponse => {
                    sessionStorage.setItem('token', tokenRefreshResponse.data.token);
                    sessionStorage.setItem('refresh-token', tokenRefreshResponse.data['refresh-token']);
                    failedRequest.response.config.headers['Authorization'] = `Bearer ${tokenRefreshResponse.data.token}`;
                    return Promise.resolve();
                })
                .catch(err => {
                    logout();
                    history.replace('/');
                    return Promise.reject();
                });
        }

        createAuthRefreshInterceptor(axios, refreshAuthLogic);
        return (
            <WrappedComponent {...props}/>
        );
    }
}

export default withRefreshHandler;