import React from "react";
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, ...res}) => {
    return (
        <Route {...res} render={(props) => (
            sessionStorage.getItem('token') !== null
                ? <Component {...props}/>
                : <Redirect to='/'/>
        )}/>
    );
}

export default PrivateRoute;