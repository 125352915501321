import React, { useContext } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import AuthContext from '../../context/auth-context';
import { sexTypes } from "../../util/types";

const useStyles = makeStyles(theme => ({
    root: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    iconButton:{
        color: theme.palette.text.primary
    },
    dateText:{
        paddingTop: '.4rem'
    }
}));

const HomeTitle = ({refreshHandler}) => {

    const classes = useStyles();

    const authContext = useContext(AuthContext);

    let welcomeText = 'Bienvenido';
    if(authContext.userSex === sexTypes.FEMENINE){
        welcomeText = 'Bienvenida';
    }

    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    let currentDate = new Date().toLocaleDateString( 'es-ES', options);

    return (
        <div className={classes.root}>
            <Grid container justify='space-between'>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant='h5'>{ welcomeText }, {authContext.userName}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body1' className={classes.dateText}>{ currentDate }</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {refreshHandler && <Grid item>
                    <Tooltip title="Actualizar">
                        <IconButton aria-label="sync" classes={{root: classes.iconButton}} onClick={refreshHandler}>
                            <SyncIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>}
            </Grid>
            <Divider/>
        </div>
    )
};

export default HomeTitle;
