import { dataTypes } from "../util/types";

const hostname = process.env.REACT_APP_SERVER_URL;
const protocol = process.env.REACT_APP_PROTOCOL;

export const serverUrl = `${protocol}://${hostname}`;

export const restUrls = {
    [dataTypes.AIRLINES]: 'airlines',
    [dataTypes.AIRPORTS]: 'airports',
    [dataTypes.ARTICLES]: 'article',
    [dataTypes.AUTH]: 'auth',
    [dataTypes.AMADEUSLOG]: 'amadeus-log',
    [dataTypes.BLACKLIST]: 'suspicious-ips',
    [dataTypes.CARDS]: 'credit-card',
    [dataTypes.CITIES]: 'city',
    [dataTypes.CLIENTS]: 'client',
    [dataTypes.EDITCLIENTS]: 'client-ct',
    [dataTypes.CLIENTTYPES]: 'client-type',
    [dataTypes.COUNTRIES]: 'country',
    [dataTypes.CURRENCIES]: 'currency',
    [dataTypes.DESTINIES]: 'destinity',
    [dataTypes.EMAILLOG]: 'email-log',
    [dataTypes.EMAILTEMPLATE]: 'email-template',
    [dataTypes.FILEUPLOADS]: 'article-upload-img',
    [dataTypes.FIREWALLS]: 'role-firewall',
    [dataTypes.FLIGHTCLASSES]: 'flight-class',
    [dataTypes.FOOTERCATEGORIES]: 'footer-category',
    [dataTypes.FOOTERMENU]: 'footer-menu',
    [dataTypes.FLIGHTS]: 'flights',
    [dataTypes.FLIGHTSCHEMAS]: 'flight-schemas',
    [dataTypes.HISTORYRESERVATIONS]: 'history-reservations',
    [dataTypes.HELPCATEGORIES]: 'help-category',
    [dataTypes.LANGUAGES]: 'lang',
    [dataTypes.LISTARTICLESNOTINCLUDE]: 'list-articles-not-include',
    [dataTypes.LOG]: 'log',
    [dataTypes.MENU]: 'menu',
    [dataTypes.MUNICIPALITIES]: 'municipality',
    [dataTypes.PAYWAYS]: 'payway',
    [dataTypes.PLACES]: 'place',
    [dataTypes.PLANES]: 'planes',
    [dataTypes.PRINCIPALMENU]: 'main-menu',
    [dataTypes.PRINCMENUCATEGORIES]: 'main-menu-category',
    [dataTypes.PROVINCES]: 'province',
    [dataTypes.PROBLEMS]: 'problems',
    [dataTypes.PUBLICITIES]: 'publicity',
    [dataTypes.REGIONS]: 'regions',
    [dataTypes.RESERVATIONS]: 'reservations',
    [dataTypes.ROLES]: 'role',
    [dataTypes.SOCIALMEDIAS]: 'social-network',
    [dataTypes.SYSTEM]: 'config',
    [dataTypes.REFRESH]: 'refresh',
    [dataTypes.TRANSLATES]: 'message',
    [dataTypes.SLIDESHOWS]: 'slide-show',
    [dataTypes.URLS]: 'url',
    [dataTypes.USERS]: 'user',
    [dataTypes.WIDGETS]: 'dashboard',
    [dataTypes.DELETEBANNERPUBLICITY]: 'delete-banner-publicity',
    [dataTypes.DELETEIMAGEARTICLE]: 'delete-image-article',
    [dataTypes.DELETEBANNERHELPCATEGORY]: 'delete-banner-help-category',
    [dataTypes.DELETEICONHELPCATEGORY]: 'delete-icon-help-category',
    [dataTypes.DELETEBANNERMENUCATEGORY]: 'delete-banner-main-menu-category',
    [dataTypes.RESERVATIONSSTATE]: 'reservation-states',
    [dataTypes.PAYMENTSOUT]: 'tm-payments'
}
