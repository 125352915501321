import {useMutation} from "react-query";

import {dataTypes} from "../../../util/types";
import {restUrls} from "../../../http/urls";
import axiosInstance from "../../../http/axios";

const login = ({userName, password}) => {
    const data = {
        userName: userName,
        password: password
    };
    return axiosInstance.post(restUrls[dataTypes.AUTH], data, { skipAuthRefresh: true });
}

export default function useMutationLogin(){
    return useMutation(login);
}